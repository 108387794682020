import React from 'react';
import './App.css';

function App() {
  // List of reasons
  const reasons = [
    "my cat needs me",
    "it's raining",
    "I lost my gym shoes",
    "I’m still sore from yesterday",
    "Netflix just released my favorite show",
    "my dog looked sad",
    "I can't find my water bottle",
    "it's too sunny",
    "it's too cloudy",
    "I'm on a chocolate diet",
    "my favorite gym machine is probably occupied",
    "I'm allergic to sweat",
    "my horoscope said to avoid strenuous activities",
    "I’m conserving energy for the zombie apocalypse",
    "I already went yesterday",
    "I’m practicing telepathy with my goldfish",
    "I’m too busy reading every book ever written",
    "my socks don’t match",
    "I’m waiting for my gym clothes to come out of retirement",
    "I’m conducting a highly scientific couch comfort study",
    "I’m training my taste buds to enjoy healthy food",
    "I’m building a fort out of gym excuses",
    "I’m still waiting for my Hogwarts letter",
    "I don’t want to overachieve",
    "my imaginary friend doesn’t want to go",
    "I need to recharge my motivation",
    "I'm practicing minimalism and gym is too much",
    "my plants need me",
    "I can't find the exit door",
    "I'm too busy finding Waldo",
    "my fish is drowning",
    "I’m too busy converting oxygen into CO2",
    "the stars aren't aligned yet",
    "I’m busy counting the grains of rice in a bag",
    "I’m preparing for a marathon... of TV shows",
    "I’m still trying to untangle my headphones",
    "I’m mastering the art of invisibility",
    "I’m waiting for a sign",
    "I’m busy thinking of reasons not to go",
    "I’m standing still to confuse my GPS",
    "I’m trying to break a world record for procrastination",
    "I’m waiting for my motivation to come back",
    "I'm allergic to the gym",
    "I’m scared of towel theft",
    "I’m busy being a couch potato",
    "I’m training my pet rock",
    "I’m busy with a 24-hour blinking marathon",
    "I’m waiting for the weather to change",
    "I’m perfecting my soup recipe",
    "my imaginary dog ate my gym clothes",
    "I’m waiting for the sequel of yesterday",
    "I’m learning how to breathe",
    "I’m trying to solve a Rubik's cube",
    "I’m waiting for a full moon",
    "I’m trying to count backwards from infinity",
    "I’m busy thinking about the meaning of life",
    "I’m trying to watch paint dry",
    "I’m trying to grow taller by sleeping",
    "I’m waiting for my second cup of coffee to kick in",
    "I’m busy watching grass grow",
    "I’m learning how to fly",
    "I’m still waiting for my superpowers to activate",
    "I'm calculating the exact age of the universe",
    "I’m learning how to cook water",
    "I’m practicing how to stay still",
    "I’m busy making a list of things I’m never going to do",
    "I’m waiting for the aliens to abduct me",
    "I’m busy mastering the art of doing nothing",
    "I’m avoiding any form of physical exertion",
    "I’m busy waiting for the kettle to boil",
    "I’m trying to communicate with my future self",
    "I’m busy perfecting the art of staying in bed",
    "I’m waiting for a leap year",
    "I’m trying to learn how to blink in Morse code",
    "I’m afraid of commitment",
    "I’m busy trying to break a world record for yawning",
    "I’m searching for the last digit of pi",
    "I’m waiting for my luck to change",
    "I’m perfecting my posture on the couch",
    "I’m trying to learn how to whistle",
    "I’m stuck in an existential crisis",
    "I’m afraid I might get too strong",
    "I’m waiting for my pizza to arrive",
    "I’m busy making snow angels in the sand",
    "I’m waiting for the stars to fall",
    "I’m learning how to cook ice",
    "I’m trying to figure out how to read minds",
    "I’m waiting for my pet unicorn to wake up",
    "I’m trying to finish knitting a sweater",
    "I’m busy trying to solve world peace",
    "I’m busy building a ship in a bottle",
    "I’m waiting for the rain to stop",
    "I’m trying to find the end of the rainbow",
    "I’m busy watching the clouds",
    "I’m waiting for my hair to grow",
    "I’m practicing how to stay awake in my dreams",
    "I’m trying to figure out why chickens cross roads",
    "I’m waiting for the right moment",
    "I’m trying to learn how to juggle",
    "I’m busy sorting my pen collection",
    "I’m trying to learn how to time travel",
    "I’m busy looking for a needle in a haystack",
    "I’m waiting for a genie to grant my wishes",
    "I’m trying to learn how to walk on water",
    "I’m too busy trying to become a meme",
    "I’m waiting for a solar eclipse"
  ];


  // Function to get a random reason
  const getRandomReason = () => {
    return reasons[Math.floor(Math.random() * reasons.length)];
  };

  // State to store the selected reason
  const [reason, setReason] = React.useState(getRandomReason());

  // Effect to update reason on every refresh
  React.useEffect(() => {
    setReason(getRandomReason());
  }, []); // The empty array ensures this effect runs only once on mount

  const changeReason = () => {
    setReason(getRandomReason());
  };

  return (
    <div className="App">
      <header className="App-header">
        <h4>I can't go to the gym today because of climate change.</h4>
        <p className="reason">Also, {reason}.</p>
        <img src="/gym-avoid-cartoon.png" alt="Cartoon character avoiding the gym" />
        <button onClick={changeReason}>Excuse Generator 3000</button>
        <footer>Created by Ashley Van Laer - not proud of this creation...</footer>
      </header>
    </div>
  );
}

export default App;
